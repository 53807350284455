import { ThreeCanvas, ThreeControls } from '.';
import Scene from './scenes/Scene';

export const Three = () => {
    
	return (
		<ThreeCanvas>
			<ThreeControls/>
			<Scene/>
		</ThreeCanvas>
	);

};