import { animations, useGlobalContext } from '../../context/GlobalContext';

export const DCAnimations = () => {

	const {setAnimation} = useGlobalContext();

	return(
		<>
			{animations.map((anim, index) => {
				return <button onClick={() => setAnimation(anim)} key={index}>{anim}</button>;
			})}
		</>
	);
};
