/* eslint-disable react/no-unknown-property */
import { useGlobalContext } from '../../context/GlobalContext';
import { Model as Male1 } from '../models/Male_iteration_01';
import { Model as Male2 } from '../models/Male_iteration_02';
import { Model as Male3 } from '../models/Male_iteration_03';
import { Model as Male4 } from '../models/Male_iteration_04';
import { Model as Megan } from '../models/Megan';

import { Text } from '@react-three/drei';

// import { Model as Male2 } from '../models/male02';

export const Scene = () => {

	const {setConversation} = useGlobalContext();


	return (
		<>	
			{/* <Megan position={[-2,0,0]}/> */}
			<Male1 onClick={() => setConversation(1)} position={[0,0,0]}/>
			<Male2 onClick={() => setConversation(2)} position={[2,0,0]}/>
			<Male3 onClick={() => setConversation(3)} position={[4,0,0]}/>
			<Male4 onClick={() => setConversation(4)} position={[6,0,0]}/>
			<Text position={[0,-1,0]} color={'black'}>1</Text>
			<Text position={[2,-1,0]} color={'black'}>2</Text>
			<Text position={[4,-1,0]} color={'black'}>3</Text>
			<Text position={[6,-1,0]} color={'black'}>4</Text>

			{/* <mesh position={[0,-0.1,0]} rotation={[0,0,0]} receiveShadow>
				<boxGeometry args={[10,0.2,10]} />
				<meshStandardMaterial color={'white'}/>
			</mesh> */}
		</>
	);

}; 

export default Scene;