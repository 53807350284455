/* eslint-disable react/no-unknown-property */
import { Environment } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

export const ThreeCanvas = (props: {children: ReactNode}) => {

	return (
		<CanvasContainer>
			<Canvas camera={{position: [0, 1, 3], fov: 60}} frameloop={'always'} shadows>
				<directionalLight position={[1,10,5]} intensity={0.8} color={'white'} castShadow shadow-mapSize={[1024,1024]}/>
				<ambientLight intensity={0.5} color={'white'}/>
				{props.children}
			</Canvas>
		</CanvasContainer>
	);
	
};

// styled components

const CanvasContainer = styled.div`
	height: 100%;
    width: 100%;
`; 