import { DeveloperConsole } from './console/DeveloperConsole';
import { GlobalContextProvider } from './context/GlobalContext';
import Interface from './interface/Interface';
import CustomThemeProvider from './style/CustomThemeProvider';
import { Three } from './three/Three';

function App() {
	return (
		<CustomThemeProvider>
			<GlobalContextProvider>
				<DeveloperConsole/>
				<Three/>
				<Interface/>
			</GlobalContextProvider>
		</CustomThemeProvider>
	);
}

export default App;
