import { expressions, useGlobalContext } from '../../context/GlobalContext';

export const DCExpressions = () => {

	const {expression, setExpression} = useGlobalContext();

	return(
		<>
			{ expressions.map(expression => {
				return <button key={`dc-expressions-${expression}`} onClick={() => setExpression(expression)}>{expression}</button>;
			})}
		</>
	);
};
