import { AnimationAction } from 'three';

export const executeCrossFade = ( startAction: AnimationAction, endAction: AnimationAction, duration: number, resetAtEnd = false) => {

	// Not only the start action, but also the end action must get a weight of 1 before fading
	// (concerning the start action this is already guaranteed in this place)

	setWeight( endAction, 1 );
	endAction.time = 0;

	// Crossfade with warping - you can also try without warping by setting the third parameter to false

	startAction.crossFadeTo( endAction, duration, true).play();
	// return;
	if(!resetAtEnd) return;
	setTimeout(() => {
		executeCrossFade(endAction, startAction, duration);
	}, ((endAction.getClip().duration - duration) * 1000));

};

// This function is needed, since animationAction.crossFadeTo() disables its start action and sets
// the start action's timeScale to ((start animation's duration) / (end animation's duration))

export const setWeight = ( action, weight ) => {

	action.enabled = true;
	action.setEffectiveTimeScale( 1 );
	action.setEffectiveWeight( weight );

};