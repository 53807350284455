import { useState } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useGlobalContext } from '../context/GlobalContext';
import render01 from '../assets/images/renders/render_it1_angry.png';
import render02 from '../assets/images/renders/render_it1_happy.png';
import render03 from '../assets/images/renders/render_it1_neutral.png';
import render04 from '../assets/images/renders/render_it2_angry.png';
import render05 from '../assets/images/renders/render_it2_happy.png';
import render06 from '../assets/images/renders/render_it2_neutral.png';
import render07 from '../assets/images/renders/render_it3_angry.png';
import render08 from '../assets/images/renders/render_it3_happy.png';
import render09 from '../assets/images/renders/render_it3_neutral.png';
import render10 from '../assets/images/renders/render_it4_angry.png';
import render11 from '../assets/images/renders/render_it4_happy.png';
import render12 from '../assets/images/renders/render_it4_neutral.png';

const renders = [
	render01,
	render02,
	render03,
	render04,
	render05,
	render06,
	render07,
	render08,
	render09,
	render10,
	render11,
	render12,
];

const Conversation = () => {

	const {conversation, setConversation} = useGlobalContext();

	const [activeRender, setActiveRender] = useState<number>(0);

	return ( 
		<>
			{ conversation &&
				<Container>
					<Close onClick={() => setConversation(0)}>X</Close>
					<Person render={renders[activeRender + (conversation > 1 ? ((conversation - 1) * 3) : 0)]}/>
					<Box onClick={() => setActiveRender(ac => ac == 2 ? 0 : ac + 1)}>
						Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero veritatis amet minima, quas reprehenderit porro vel ipsam consectetur inventore est pariatur quae dolore aliquam! Accusamus vero tenetur eius earum culpa officia voluptatem quos totam voluptas dicta aliquam, nihil dolor eos modi quod laborum maiores. Voluptas, qui tempore? Nostrum, at atque obcaecati, ipsum, velit labore unde porro optio tempora odio culpa sapiente architecto quo quibusdam blanditiis inventore accusamus corporis quam aperiam consectetur non ab neque maiores magni! Quia perspiciatis sed voluptates hic, esse consequuntur aliquid quibusdam suscipit sint beatae odio rem accusantium autem dolor quasi voluptas quidem. Ad voluptate fugiat aperiam?
					</Box>
				</Container> 
			}
		</>

	);
};

const ComeIn = keyframes`
    0%{
        opacity: 0;
        transform:translateY(+100%)
    }
    100%{
        opacity: 1;
        transform:translateY(0)
    }
`;

const Swap1 = keyframes`
    0%{
        transform: scaleX(1)
    }
    100%{
        transform: scaleX(0)
    }
`;

const Swap2 = keyframes`
    0%{
        transform: scaleX(0)
    }
    100%{
        transform: scaleX(1)
    }
`;

const Container = styled.div`
	position: absolute;
	inset:0;
	background-color: rgba(0,0,0,0.9);
	padding: 5%;
	display:flex;
	flex-direction: column;
    overflow-y: hidden;

    user-select: none;
`;

const Box = styled.div`
	height:30%;
	padding: 2em;

	color: black;

	background-color: white;
	border-radius: 25px;

    z-index: 1;
`;

const Person = styled.div<{render: string, swap: number | undefined}>`

	margin-left:10%;

	flex-grow: 1;

	height: 100%;
	width: 30%;

    animation: ${ComeIn} 1s forwards;

    background-image: url(${p => p.render});
    background-size: contain 100%;
    background-repeat: no-repeat;
    background-position: top;

`;

const Close = styled.button`
    position:absolute;
    right:0;top:0;
    height: 100px;
    width: 100px;
    cursor: pointer;
`;
 
export default Conversation;