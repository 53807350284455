/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 male_iteration_02.glb -k -t -T -p 4
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { executeCrossFade } from '../utilities/animations';
import { useGlobalContext } from '../../context/GlobalContext';

type GLTFResult = GLTF & {
  nodes: {
    eye_left005: THREE.SkinnedMesh
    eye_left005_1: THREE.SkinnedMesh
    eye_left005_2: THREE.SkinnedMesh
    eye_left005_3: THREE.SkinnedMesh
    eye_left016: THREE.SkinnedMesh
    eye_left016_1: THREE.SkinnedMesh
    eye_left016_2: THREE.SkinnedMesh
    eye_left016_3: THREE.SkinnedMesh
    eyeleft001: THREE.SkinnedMesh
    eyeleft001_1: THREE.SkinnedMesh
    eyeleft001_2: THREE.SkinnedMesh
    eyeleft001_3: THREE.SkinnedMesh
    male_01_body: THREE.SkinnedMesh
    mixamorigHips: THREE.Bone
  }
  materials: {
    eyebrow: THREE.MeshStandardMaterial
    mouth_sad: THREE.MeshStandardMaterial
    eyeleft: THREE.MeshStandardMaterial
    eyeright: THREE.MeshStandardMaterial
    mouth_happy: THREE.MeshStandardMaterial
    mouf: THREE.MeshStandardMaterial
    peopleColors: THREE.MeshStandardMaterial
  }
  animation: GLTFActions[]
}

type ActionName = 'anim_angry' | 'anim_annoyed' | 'anim_chicken_dance' | 'anim_idle' | 'anim_sad' | 'posing_angry' | 'posing_happy' | 'posing_neutral' | 'tpose_anim'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Model(props: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF('/male_iteration_02-transformed.glb') as GLTFResult;
	const { actions } = useAnimations(animations, group);

	const {expression, animation} = useGlobalContext();

	useEffect(() => {
		actions?.anim_idle?.setLoop(THREE.LoopRepeat, Infinity).play();
	}, []);

	useEffect(() => {
		const currentAction = actions.anim_idle;
		const nextAction = actions[animation];
		if(!currentAction || !nextAction) return;

		executeCrossFade(currentAction, nextAction, 0.5, true);
	},[animation]);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="male_armature_it2" position={[0, 0.0051, 0.011]}>
					<primitive object={nodes.mixamorigHips} />
					{ expression === 'angry' &&
					<group name="face_angry_it2">
						<skinnedMesh name="eye_left005" geometry={nodes.eye_left005.geometry} material={materials.eyebrow} skeleton={nodes.eye_left005.skeleton} />
						<skinnedMesh name="eye_left005_1" geometry={nodes.eye_left005_1.geometry} material={materials.mouth_sad} skeleton={nodes.eye_left005_1.skeleton} />
						<skinnedMesh name="eye_left005_2" geometry={nodes.eye_left005_2.geometry} material={materials.eyeleft} skeleton={nodes.eye_left005_2.skeleton} />
						<skinnedMesh name="eye_left005_3" geometry={nodes.eye_left005_3.geometry} material={materials.eyeright} skeleton={nodes.eye_left005_3.skeleton} />
					</group>
					}
					{ expression === 'happy' &&

					<group name="face_happy_it2">
						<skinnedMesh name="eye_left016" geometry={nodes.eye_left016.geometry} material={materials.eyebrow} skeleton={nodes.eye_left016.skeleton} />
						<skinnedMesh name="eye_left016_1" geometry={nodes.eye_left016_1.geometry} material={materials.mouth_happy} skeleton={nodes.eye_left016_1.skeleton} />
						<skinnedMesh name="eye_left016_2" geometry={nodes.eye_left016_2.geometry} material={materials.eyeleft} skeleton={nodes.eye_left016_2.skeleton} />
						<skinnedMesh name="eye_left016_3" geometry={nodes.eye_left016_3.geometry} material={materials.eyeright} skeleton={nodes.eye_left016_3.skeleton} />
					</group>
					}
					{ expression === 'neutral' &&
					<group name="face_neutral_it2">
						<skinnedMesh name="eyeleft001" geometry={nodes.eyeleft001.geometry} material={materials.eyeleft} skeleton={nodes.eyeleft001.skeleton} />
						<skinnedMesh name="eyeleft001_1" geometry={nodes.eyeleft001_1.geometry} material={materials.eyeright} skeleton={nodes.eyeleft001_1.skeleton} />
						<skinnedMesh name="eyeleft001_2" geometry={nodes.eyeleft001_2.geometry} material={materials.mouf} skeleton={nodes.eyeleft001_2.skeleton} />
						<skinnedMesh name="eyeleft001_3" geometry={nodes.eyeleft001_3.geometry} material={materials.eyebrow} skeleton={nodes.eyeleft001_3.skeleton} />
					</group>
					}
					<skinnedMesh name="male_01_body" geometry={nodes.male_01_body.geometry} material={materials.peopleColors} skeleton={nodes.male_01_body.skeleton} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/male_iteration_02-transformed.glb');
