/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 male_iteration_04.glb -k -t -T -p 4
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { executeCrossFade } from '../utilities/animations';
import { useGlobalContext } from '../../context/GlobalContext';

type GLTFResult = GLTF & {
  nodes: {
    eye_left003: THREE.SkinnedMesh
    eye_left003_1: THREE.SkinnedMesh
    eye_left003_2: THREE.SkinnedMesh
    eye_left003_3: THREE.SkinnedMesh
    eye_left001: THREE.SkinnedMesh
    eye_left001_1: THREE.SkinnedMesh
    eye_left001_2: THREE.SkinnedMesh
    eye_left001_3: THREE.SkinnedMesh
    eye_left006: THREE.SkinnedMesh
    eye_left006_1: THREE.SkinnedMesh
    eye_left006_2: THREE.SkinnedMesh
    eye_left006_3: THREE.SkinnedMesh
    male_01003: THREE.SkinnedMesh
    mixamorigHips: THREE.Bone
  }
  materials: {
    eye_left: THREE.MeshStandardMaterial
    eye_right: THREE.MeshStandardMaterial
    eyebrow: THREE.MeshStandardMaterial
    mouth_sad: THREE.MeshStandardMaterial
    ['mouth_happy.002']: THREE.MeshStandardMaterial
    mouth: THREE.MeshStandardMaterial
    peopleColors: THREE.MeshStandardMaterial
  }
  animations: GLTFActions[]
}

type ActionName = 'anim_angry' | 'anim_annoyed' | 'anim_chicken_dance' | 'anim_idle' | 'anim_sad' | 'posing_angry' | 'posing_happy' | 'posing_neutral' | 'tpose_anim'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Model(props: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF('/male_iteration_04-transformed.glb') as GLTFResult;
	const { actions } = useAnimations(animations, group);

	const {expression, animation} = useGlobalContext();

	useEffect(() => {
		actions?.anim_idle?.setLoop(THREE.LoopRepeat, Infinity).play();
	}, []);

	useEffect(() => {
		const currentAction = actions.anim_idle;
		const nextAction = actions[animation];
		if(!currentAction || !nextAction) return;

		executeCrossFade(currentAction, nextAction, 0.5, true);
	},[animation]);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="male_armature_it4" position={[0, 0.0051, 0.011]}>
					<primitive object={nodes.mixamorigHips} />
					{ expression === 'angry' && 
					<group name="face_angry">
						<skinnedMesh name="eye_left003" geometry={nodes.eye_left003.geometry} material={materials.eye_left} skeleton={nodes.eye_left003.skeleton} />
						<skinnedMesh name="eye_left003_1" geometry={nodes.eye_left003_1.geometry} material={materials.eye_right} skeleton={nodes.eye_left003_1.skeleton} />
						<skinnedMesh name="eye_left003_2" geometry={nodes.eye_left003_2.geometry} material={materials.eyebrow} skeleton={nodes.eye_left003_2.skeleton} />
						<skinnedMesh name="eye_left003_3" geometry={nodes.eye_left003_3.geometry} material={materials.mouth_sad} skeleton={nodes.eye_left003_3.skeleton} />
					</group>
					}
					{ expression === 'happy' && 
					<group name="face_happy">
						<skinnedMesh name="eye_left001" geometry={nodes.eye_left001.geometry} material={materials.eye_left} skeleton={nodes.eye_left001.skeleton} />
						<skinnedMesh name="eye_left001_1" geometry={nodes.eye_left001_1.geometry} material={materials.eye_right} skeleton={nodes.eye_left001_1.skeleton} />
						<skinnedMesh name="eye_left001_2" geometry={nodes.eye_left001_2.geometry} material={materials.eyebrow} skeleton={nodes.eye_left001_2.skeleton} />
						<skinnedMesh name="eye_left001_3" geometry={nodes.eye_left001_3.geometry} material={materials['mouth_happy.002']} skeleton={nodes.eye_left001_3.skeleton} />
					</group>
					}
					{ expression === 'neutral' && 
					<group name="face_neutral">
						<skinnedMesh name="eye_left006" geometry={nodes.eye_left006.geometry} material={materials.eye_left} skeleton={nodes.eye_left006.skeleton} />
						<skinnedMesh name="eye_left006_1" geometry={nodes.eye_left006_1.geometry} material={materials.eye_right} skeleton={nodes.eye_left006_1.skeleton} />
						<skinnedMesh name="eye_left006_2" geometry={nodes.eye_left006_2.geometry} material={materials.eyebrow} skeleton={nodes.eye_left006_2.skeleton} />
						<skinnedMesh name="eye_left006_3" geometry={nodes.eye_left006_3.geometry} material={materials.mouth} skeleton={nodes.eye_left006_3.skeleton} />
					</group>
					}
					<skinnedMesh name="male_01003" geometry={nodes.male_01003.geometry} material={materials.peopleColors} skeleton={nodes.male_01003.skeleton} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/male_iteration_04-transformed.glb');
