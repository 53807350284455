import { CameraControls } from '@react-three/drei';
import { createContext, Dispatch, useContext, useState, SetStateAction, useEffect } from 'react';

const GlobalContext = createContext<GlobalContextProps>({
	controls: undefined,
	setControls: () => null,
	animation: 'anim_idle',
	setAnimation: () => null,
	expression: 'neutral',
	setExpression:() => null,
	conversation: 0,
	setConversation: ()=> null
});

const GlobalContextProvider = (props: JSX.ElementChildrenAttribute) => {

	const [controls, setControls] = useState<CameraControls | undefined>();
	const [animation, setAnimation] = useState<AnimationState>('anim_idle');
	const [expression, setExpression] = useState<ExpressionState>('neutral');
	const [conversation, setConversation] = useState(0);
	// const [availableAnimations, setAvailableAnimations] = useState<expres>([]);

	const passedValues = {
		expression,
		setExpression,
		animation,
		setAnimation,
		controls,
		setControls,
		conversation,
		setConversation
	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues}}>
			<>{props.children}</>
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {

	// general
	controls: CameraControls | undefined,
	setControls: Dispatch<SetStateAction<CameraControls | undefined>>,
	animation: AnimationState,
	setAnimation: Dispatch<SetStateAction<AnimationState>>
	expression: ExpressionState
	setExpression: Dispatch<SetStateAction<ExpressionState>>
	conversation: number,
	setConversation: Dispatch<SetStateAction<number>>

}

export const expressions = ['angry' , 'happy' , 'neutral'] as const;
export const animations = ['anim_idle' , 'anim_angry' , 'anim_sad', 'anim_annoyed', 'anim_chicken_dance'] as const;

export type ExpressionState = typeof expressions[number];
export type AnimationState = typeof animations[number];

// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
