/* eslint-disable */
import { CameraControlsProps, CameraControls} from '@react-three/drei';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useGlobalContext } from '../context/GlobalContext';

const config: CameraControlsProps = {
	minPolarAngle: 1.2,
	maxPolarAngle: 1.9,
	dollySpeed: 1,
	truckSpeed: 1,
	azimuthRotateSpeed: 0, // disable azimute rotation
	polarRotateSpeed: 0.2
}

export const ThreeControls = () => {

	const { setControls } = useGlobalContext();

	const cameraControls = useRef<CameraControls>(null);

	const initializeCamera = () => {
		if(!cameraControls.current) return;

		// set values
		// cameraControls.current.minPolarAngle = config.minPolarAngle ? config.minPolarAngle : 0;
		// cameraControls.current.maxPolarAngle = config.maxPolarAngle ? config.maxPolarAngle : 0;


		// cameraControls.current.dollySpeed = config.dollySpeed ? config.dollySpeed : 0;
		// cameraControls.current.truckSpeed = config.truckSpeed ? config.truckSpeed : 0;

		cameraControls.current.setTarget(0,1,0);
		cameraControls.current.setPosition(0,1.7,2);
		// cameraControls.current.setBoundary(new THREE.Box3(new THREE.Vector3(-2,1,-2), new THREE.Vector3(2,1,2)))
		// cameraControls.current.boundaryEnclosesCamera = true;

		// set events

		cameraControls.current.addEventListener('controlend', () => cameraControls.current && cameraControls.current.rotatePolarTo(1.5, true))
		cameraControls.current.rotatePolarTo(1.5);

		// set user controls

		
		
		setControls && setControls(cameraControls.current)
	}

	useEffect(() => {
		initializeCamera();
	},[cameraControls.current])

	return (
		<CameraControls 
			ref={cameraControls}
			{...config}
		/>
	);
};