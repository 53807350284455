import Conversation from './Conversation';

const Interface = () => {
	return ( 
		<>
			<Conversation/>
		</> 
	);
};
 
export default Interface;