/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 male_iteration_01.glb -k -t -T -p 4
*/

import * as THREE from 'three';
import React, { useRef, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { useGlobalContext } from '../../context/GlobalContext';
import { executeCrossFade } from '../utilities/animations';

type GLTFResult = GLTF & {
  nodes: {
    mouth_happy001: THREE.SkinnedMesh
    mouth_happy001_1: THREE.SkinnedMesh
    mouth_happy001_2: THREE.SkinnedMesh
    eyebrow009: THREE.SkinnedMesh
    eyebrow009_1: THREE.SkinnedMesh
    eyebrow009_2: THREE.SkinnedMesh
    eyebrow004: THREE.SkinnedMesh
    eyebrow004_1: THREE.SkinnedMesh
    eyebrow004_2: THREE.SkinnedMesh
    male_01_iteration1: THREE.SkinnedMesh
    mixamorigHips: THREE.Bone
  }
  materials: {
    ['mouth_happy.001']: THREE.MeshStandardMaterial
    eye_brown: THREE.MeshStandardMaterial
    eyebrow: THREE.MeshStandardMaterial
    peopleColors: THREE.MeshStandardMaterial
  }
  animation: GLTFActions[]
}

type ActionName = 'anim_angry' | 'anim_annoyed' | 'anim_chicken_dance' | 'anim_idle' | 'anim_sad' | 'posing_angry' | 'posing_happy' | 'posing_neutral' | 'tpose_anim'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function Model(props: JSX.IntrinsicElements['group']) {
	const group = useRef<THREE.Group>(null);
	const { nodes, materials, animations } = useGLTF('/male_iteration_01-transformed.glb') as GLTFResult;
	const { actions } = useAnimations(animations, group);

	const {expression, animation} = useGlobalContext();

	useEffect(() => {
		actions?.anim_idle?.setLoop(THREE.LoopRepeat, Infinity).play();
	}, []);

	useEffect(() => {
		const currentAction = actions.anim_idle;
		const nextAction = actions[animation];
		if(!currentAction || !nextAction) return;

		executeCrossFade(currentAction, nextAction, 0.5, true);
	},[animation]);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<group name="male_armature_it1" position={[0, 0.0051, 0.011]}>
					<primitive object={nodes.mixamorigHips} />
					{ expression === 'angry' && 
					<group name="face_angry_it1">
						<skinnedMesh name="mouth_happy001" geometry={nodes.mouth_happy001.geometry} material={materials['mouth_happy.001']} skeleton={nodes.mouth_happy001.skeleton} />
						<skinnedMesh name="mouth_happy001_1" geometry={nodes.mouth_happy001_1.geometry} material={materials.eye_brown} skeleton={nodes.mouth_happy001_1.skeleton} />
						<skinnedMesh name="mouth_happy001_2" geometry={nodes.mouth_happy001_2.geometry} material={materials.eyebrow} skeleton={nodes.mouth_happy001_2.skeleton} />
					</group>
					}
					{ expression === 'happy' && 
					<group name="face_happy_it1">
						<skinnedMesh name="eyebrow009" geometry={nodes.eyebrow009.geometry} material={materials.eyebrow} skeleton={nodes.eyebrow009.skeleton} />
						<skinnedMesh name="eyebrow009_1" geometry={nodes.eyebrow009_1.geometry} material={materials.eye_brown} skeleton={nodes.eyebrow009_1.skeleton} />
						<skinnedMesh name="eyebrow009_2" geometry={nodes.eyebrow009_2.geometry} material={materials['mouth_happy.001']} skeleton={nodes.eyebrow009_2.skeleton} />
					</group>
					}
					{ expression === 'neutral' && 
					<group name="face_neutral_it1">
						<skinnedMesh name="eyebrow004" geometry={nodes.eyebrow004.geometry} material={materials.eyebrow} skeleton={nodes.eyebrow004.skeleton} />
						<skinnedMesh name="eyebrow004_1" geometry={nodes.eyebrow004_1.geometry} material={materials.eye_brown} skeleton={nodes.eyebrow004_1.skeleton} />
						<skinnedMesh name="eyebrow004_2" geometry={nodes.eyebrow004_2.geometry} material={materials['mouth_happy.001']} skeleton={nodes.eyebrow004_2.skeleton} />
					</group>
					}
					<skinnedMesh name="male_01_iteration1" geometry={nodes.male_01_iteration1.geometry} material={materials.peopleColors} skeleton={nodes.male_01_iteration1.skeleton} />
				</group>
			</group>
		</group>
	);
}

useGLTF.preload('/male_iteration_01-transformed.glb');
