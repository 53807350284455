import { useGlobalContext } from '../../context/GlobalContext';

const DCCamera = () => {

	const {controls} = useGlobalContext();

	return ( 
		<>
			{controls && 
			<label>polarAngle: {controls.polarAngle}</label>
			}
		</>
	);
};
 
export default DCCamera;